<template>
  <div class="text-center mt-3">
    <div v-if="isPackageDownloadVisible" class="mb-6 text-center">
      <v-alert v-if="showDownloadWarning" type="warning" dense>
        <i18n path="packageDownloadWarning.message" tag="span">
          <template v-slot:requirementsLink>
            <a
              class="white--text"
              :href="requirementsLink"
              target="_blank"
              >{{ $t("packageDownloadWarning.requirementsLink") }}</a>
          </template>
        </i18n>
      </v-alert>
      <v-overflow-btn
        v-if="packageDownloadTypes"
        depressed
        x-large
        :class="downloadPackageButtonClasses"
        :items="packageDownloadTypes"
        :value="defaultPackageType"
        segmented
        item-value="key"
        data-cy="download-package"
        :loading="downloadingPackage"
        loader-height="5"
        :disabled="downloadingPackage"
      >
        <template #item="{ item }">
          {{ item.value }}
        </template>
      </v-overflow-btn>
      <div>
        <span v-t="'productDetails.version'" />
        {{ productVersion }}
      </div>
    </div>
    <div v-else class="mb-5">
      {{ contract.product.downloadPackageMessage }}
    </div>
    <material-product-details-change-plan-modal-window
      v-if="isChangePlanAllowed"
      :product="contract.product"
      :client-id="clientId"
    />
    <div v-else type="info" dense border="left">
      {{ contract.product.changePlanMessage }}
    </div>
    <!-- eslint-disable vue/no-v-html vue-i18n/no-v-html -->
    <div
      v-if="contract.product_version === 'v10'"
      class="my-6"
      v-html="
        $t('productDetails.upgradeYourPlan', {
          manager: client.manager.name,
          email: `<a href='mailto:${client.manager.email}'>${client.manager.email}</a>`,
        })
      "
    />
    <!-- eslint-enable vue/no-v-html vue-i18n/no-v-html -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TYPE_SERVER, ADMIN_ACCESS_LEVEL, PURCHASE_ORDERS_ACCESS_LEVEL, PERMISSIONS_NAME } from "@/constants/app";

export default {
  data: () => {
    return {
      requirementsUrls: {
        /*eslint-disable */
        easyredmine_com:
          "https://www.easyredmine.com/documentation-of-easy-redmine/article/hardware-and-software-requirements-for-the-server-solution#Software%20requirements",
        ep_com:
          "https://www.easyproject.com/documentation/article/hardware-and-software-requirements-for-the-server-solution#Software%20requirements",
        /*eslint-enable */
      },
    };
  },
  computed: {
    ...mapGetters("client", ["clientById"]),
    downloadPackageButtonClasses() {
      const baseClasses = "mb-2 download-package-btn";

      return this.downloadingPackage ? `${baseClasses} disabled` : baseClasses;
    },
    client() {
      return this.clientById(this.clientId);
    },
    clientId() {
      return this.$route.params.clientId;
    },
    contractId() {
      return this.$route.params.contractId;
    },
    productVersion() {
      return this.contract.product.version ? this.contract.product.version : this.$t("productDetails.unknownVersion");
    },
    contract() {
      return this.$store.getters["contract/contract"]({
        clientId: this.clientId,
        contractId: this.contractId,
      });
    },
    downloadingPackage() {
      return Boolean(this.$store.state.productDetails.downloadingPackage);
    },
    clientPermissions() {
      return JSON.parse(localStorage.getItem(PERMISSIONS_NAME));
    },
    isChangePlanAllowed() {
      return (
        this.clientPermissions &&
        this.contract.product.changePlanVisible &&
        (this.clientPermissions.includes(ADMIN_ACCESS_LEVEL) ||
          this.clientPermissions.includes(PURCHASE_ORDERS_ACCESS_LEVEL))
      );
    },
    isPackageDownloadVisible() {
      return this.contract.product.type === TYPE_SERVER && this.contract.product.downloadPackageVisible;
    },
    packageDownloadTypes() {
      return this.contract.product.packageDownloadTypes
        ? this.contract.product.packageDownloadTypes.map((packageType) => ({
            key: packageType.key,
            value: packageType.name,
            text: `${this.$i18n.t("productDetails.downloadPackage")} (${packageType.key})`,
            callback: () => {
              this.downloadPackage({
                clientId: this.clientId,
                contractId: this.contractId,
                packageType: packageType.key,
              });
            },
          }))
        : null;
    },
    defaultPackageType() {
      return this.contract.product.packageDownloadTypes && this.contract.product.packageDownloadTypes.length > 0
        ? this.contract.product.packageDownloadTypes[0].key
        : null;
    },
    showDownloadWarning() {
      return this.packageDownloadTypes && this.packageDownloadTypes.length > 0;
    },
    requirementsLink() {
      const defaultLink = this.requirementsUrls.ep_com;
      return this.requirementsUrls[this.contract.brand?.toLowerCase()] || defaultLink;
    },
  },
  methods: {
    ...mapActions("productDetails", ["downloadPackage"]),
  },
};
</script>

<style scoped>
.download-package-btn >>> .v-select__selections {
  width: fit-content;
}
.download-package-btn >>> .v-input__slot {
  width: fit-content;
  margin: auto;
}
.download-package-btn >>> .v-select__slot {
  cursor: pointer !important;
  background-color: var(--v-primary-base);
  width: fit-content;
}

.download-package-btn.disabled >>> .v-select__slot {
  background-color: var(--v-primary-lighten2);
}

.download-package-btn >>> button,
.download-package-btn >>> i {
  color: white;
}
.download-package-btn >>> input {
  cursor: pointer !important;
  width: 1em;
}
.download-package-btn >>> .v-input__control:before {
  display: none;
}
.download-package-btn >>> .v-text-field__details {
  display: none;
}

.download-package-btn.disabled >>> .v-icon.v-icon--disabled {
  color: var(--v-secondary-lighten5) !important;
}
</style>
