var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mt-3"},[(_vm.isPackageDownloadVisible)?_c('div',{staticClass:"mb-6 text-center"},[(_vm.showDownloadWarning)?_c('v-alert',{attrs:{"type":"warning","dense":""}},[_c('i18n',{attrs:{"path":"packageDownloadWarning.message","tag":"span"},scopedSlots:_vm._u([{key:"requirementsLink",fn:function(){return [_c('a',{staticClass:"white--text",attrs:{"href":_vm.requirementsLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("packageDownloadWarning.requirementsLink")))])]},proxy:true}],null,false,3652689612)})],1):_vm._e(),(_vm.packageDownloadTypes)?_c('v-overflow-btn',{class:_vm.downloadPackageButtonClasses,attrs:{"depressed":"","x-large":"","items":_vm.packageDownloadTypes,"value":_vm.defaultPackageType,"segmented":"","item-value":"key","loading":_vm.downloadingPackage,"loader-height":"5","disabled":_vm.downloadingPackage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}],null,false,826708554)}):_vm._e(),_c('div',[_c('span',{directives:[{name:"t",rawName:"v-t",value:('productDetails.version'),expression:"'productDetails.version'"}]}),_vm._v(" "+_vm._s(_vm.productVersion)+" ")])],1):_c('div',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.contract.product.downloadPackageMessage)+" ")]),(_vm.isChangePlanAllowed)?_c('material-product-details-change-plan-modal-window',{attrs:{"product":_vm.contract.product,"client-id":_vm.clientId}}):_c('div',{attrs:{"type":"info","dense":"","border":"left"}},[_vm._v(" "+_vm._s(_vm.contract.product.changePlanMessage)+" ")]),(_vm.contract.product_version === 'v10')?_c('div',{staticClass:"my-6",domProps:{"innerHTML":_vm._s(
      _vm.$t('productDetails.upgradeYourPlan', {
        manager: _vm.client.manager.name,
        email: ("<a href='mailto:" + (_vm.client.manager.email) + "'>" + (_vm.client.manager.email) + "</a>"),
      })
    )}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }